import {
  LocalShipping,
  Sell,
  People,
  PriceCheck,
  IosShare,
  RemoveDone,
} from "@mui/icons-material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

import BarChartIcon from "@mui/icons-material/BarChart";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LockIcon from "@mui/icons-material/Lock";
import TransformIcon from "@mui/icons-material/Transform";

const logisticSection = [
  {
    href: "/collections",
    icon: LocalShipping,
    title: "Recolecciones",
  },
  {
    href: "/prices",
    icon: Sell,
    title: "Precios Estándar",
  },
];

const mutantSection = [
  {
    href: "/users",
    icon: People,
    title: "Generadores",
    children: [
      {
        href: "/users/individuals",
        title: "Personas",
      },
      {
        href: "/users/businesses",
        title: "Negocios",
      },
    ],
  },
  {
    href: "/withdrawals",
    icon: PriceCheck,
    title: "Retiros",
  },
];

const routesSection = [
  {
    href: "/routes",
    icon: WhereToVoteIcon,
    title: "Puntos",
  },
];

const controlSection = [
  {
    href: "/control",
    icon: BarChartIcon,
    title: "Métricas",
  },
];

const inventorySection = [
  // {
  //   href: "/inventory",
  //   icon: BarChartIcon,
  //   title: "Saldos y ajustes",
  // },
  {
    href: "/inventory/transfers",
    icon: TransformIcon,
    title: "Transferencias",
  },
  {
    href: "/verification",
    icon: MoveUpIcon,
    title: "Verificación",
  },
  {
    href: "/inventory/transformations",
    icon: PublishedWithChangesIcon,
    title: "Transformaciones",
  },
  {
    href: "/adjustment",
    icon: RemoveDone,
    title: "Adjustment",
  },

  //   href: "/compact_pet",
  //   icon: DashboardIcon,
  //   title: "Compactar PET",
  // },
];

const salesSection = [
  {
    href: "/transactions/sales",
    icon: IosShare,
    title: "Ventas",
  },
  {
    href: "/transactions/clients",
    icon: GroupAddIcon,
    title: "Clientes",
  },
];

const settingsSection = [
  {
    href: "/settings",
    icon: LockIcon,
    title: "Seguridad",
    children: [
      {
        href: "/settings/changePasswordStep1",
        title: "Cambiar contraseña",
      },
    ],
  },
];

const navItems = [
  {
    title: "Operaciones",
    pages: logisticSection,
  },
  {
    title: "Mutantes",
    pages: mutantSection,
  },
  {
    title: "Control de inventario",
    pages: inventorySection,
  },
  {
    title: "Transacciones",
    pages: salesSection,
  },
  {
    title: "Rutas",
    pages: routesSection,
  },
  {
    title: "Tablero de control",
    pages: controlSection,
  },
  {
    title: "Configuraciones",
    pages: settingsSection,
  },
];

export default navItems;
