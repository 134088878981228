import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";
import { green, red, yellow } from "@mui/material/colors";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FilterList as FilterListIcon } from "@mui/icons-material";

import CustomCircularProgress from "../../components/controlview/CustomCircularProgress";
import CollectionsRoutesMap from "../../components/old/googlemaps/old/CollectionsRoutesMap";
import CurrentLocation from "../../components/old/googlemaps/old/socket.io/CurrentLocation";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import {
  fetchAssignedCollections,
  fetchCollectedCollections,
  fetchCollectorsList,
  fetchFailedCollections,
  fetchWarehouses,
  setWarehouseidFilter,
} from "../../redux/old/slices/collectionSlicer";
import { updateSelectedCollectorsId } from "../../redux/old/slices/googleMapsSlicer";

export default function ControlView() {
  const {
    assignedCollections,
    collectedCollections,
    failedCollections,
    collectorsList,
    warehouseIdFilter,
    warehouses,
  } = useSelector((state) => state.collection);
  const dispatch = useDispatch();
  const { PP2 } = usePixelPerfect();

  const collections = [
    assignedCollections,
    collectedCollections,
    failedCollections,
  ];

  const total =
    collections.map((item) => item).reduce((a, b) => a + b, 0) !== 0
      ? collections.map((item) => item).reduce((a, b) => a + b, 0)
      : 0;

  const divider = total === 0 ? 1 : total;

  const data = [
    {
      label: "Asignadas",
      value: assignedCollections,
      percentage: Math.round((assignedCollections / divider) * 100),
      color: yellow[500],
    },
    {
      label: "Recolectadas",
      value: collectedCollections,
      percentage: Math.round((collectedCollections / divider) * 100),
      color: green[400],
    },
    {
      label: "Fallidas",
      value: failedCollections,
      percentage: Math.round((failedCollections / divider) * 100),
      color: red[400],
    },
  ];

  const today = new Date();
  const currentMinutes = today.getMinutes();
  const roundedMinutes = Math.floor(currentMinutes / 5) * 5;
  today.setMinutes(roundedMinutes);
  const dateFilter = today.toISOString().split("T")[0];

  const [lastUpdateTime, setLastUpdateTime] = useState(new Date());
  const [selectWarehouseModal, setSelectWarehouseModal] = useState(false);
  const [currentHour, setCurrentHour] = useState(
    lastUpdateTime.toLocaleTimeString("es-CO", {
      timeZone: "America/Bogota",
      hour: "numeric",
      minute: "numeric",
    })
  );

  const fetchData = async () => {
    await dispatch(
      fetchAssignedCollections({
        dateFilter: dateFilter,
        warehouseId: warehouseIdFilter,
      })
    );
    await dispatch(
      fetchCollectedCollections({
        dateFilter: dateFilter,
        warehouseId: warehouseIdFilter,
      })
    );
    await dispatch(
      fetchFailedCollections({
        dateFilter: dateFilter,
        warehouseId: warehouseIdFilter,
      })
    );
    setLastUpdateTime(new Date());
  };

  const getCollectorsList = useCallback(async () => {
    //ReduxToolkit Get
    if (collectorsList.length !== 0) {
      const allCollectorsIdArray = collectorsList.map(
        (collectorInfo) => collectorInfo.id
      );
      dispatch(updateSelectedCollectorsId(allCollectorsIdArray));
    }
  }, [dispatch, collectorsList]);

  useEffect(() => {
    dispatch(fetchCollectorsList());
  }, [dispatch]);

  useEffect(() => {
    getCollectorsList();
  }, [getCollectorsList]);

  useEffect(() => {
    fetchData(); // Obtener los datos iniciales al montar el componente
  }, [warehouseIdFilter]);

  useEffect(() => {
    // Configuración del intervalo para actualizar cada 5 minutos
    const interval = setInterval(() => {
      fetchData(); // Obtener los datos actualizados cada 5 minutos
      const currentMinutes = new Date().getMinutes();
      const roundedMinutes = Math.floor(currentMinutes / 5) * 5;
      const currentHour = new Date();
      currentHour.setMinutes(roundedMinutes);
      setCurrentHour(
        currentHour.toLocaleTimeString("es-CO", {
          timeZone: "America/Bogota",
          hour: "numeric",
          minute: "numeric",
        })
      );
    }, 5 * 60 * 1000);

    return () => clearInterval(interval); // Limpiar el intervalo al desmontar el componente
  }, [lastUpdateTime]);

  //Filtro por Bodegas

  const getWarehouses = async () => {
    dispatch(fetchWarehouses()); //Redux Get
  };

  useEffect(() => {
    getWarehouses();
  }, []);

  const updateWareHouseIdFilter = (data) => {
    dispatch(setWarehouseidFilter(data.warehouseId)); //Redux Get
    setSelectWarehouseModal(false);
  };

  const initialValue = {
    warehouseId: warehouseIdFilter,
  };

  const formikWarehouseId = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: (warehouseId) => {
      updateWareHouseIdFilter(warehouseId);
    },
  });

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        alignSelf: "center",
        flexDirection: "row",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "50%",
          height: "100%",
        }}
      >
        <Box
          display="flex"
          style={{ alignItems: "center", justifyContent: "space-evenly" }}
        >
          <Typography style={{ fontWeight: 700, fontSize: PP2(20) }}>
            CONTROL DE RECOLECCIONES
          </Typography>
          <IconButton
            aria-label="Filter list"
            size="large"
            onClick={(e) => setSelectWarehouseModal(true)}
          >
            <FilterListIcon
              style={{
                width: PP2(30),
                height: PP2(30),
              }}
            />
          </IconButton>
        </Box>

        <Typography style={{ fontWeight: 500, fontSize: PP2(20) }}>
          {dateFilter}
        </Typography>
        <Typography style={{ fontWeight: 500, fontSize: PP2(20) }}>
          Hora de última actualización: {currentHour}
        </Typography>
        {data.map((collectionInfo, index) => {
          return (
            <CustomCircularProgress
              title={collectionInfo.label}
              percentage={collectionInfo.percentage}
              quantity={collectionInfo.value}
              lastUpdateTime={lastUpdateTime}
              total={total}
              key={index}
            />
          );
        })}
      </Box>
      <Box
        style={{
          width: PP2(1900),
          height: PP2(950),
        }}
      >
        <CollectionsRoutesMap />
        <CurrentLocation />
      </Box>

      {/* Modal filtro por bodega */}

      <Dialog
        open={selectWarehouseModal}
        onClose={() => setSelectWarehouseModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formikWarehouseId.handleSubmit}>
          <DialogTitle
            id="form-dialog-warehouse"
            style={{ fontWeight: 700, fontSize: PP2(18) }}
          >
            Seleccionar bodega
          </DialogTitle>
          <DialogContent style={{ fontSize: PP2(16) }}>
            <Box style={{ color: "grey" }}>
              Mutante, al filtrar, las métricas que se{" "}
              <div> mostrarán en pantalla serán únicamente</div>{" "}
              <div> respecto a la bodega seleccionada</div>
            </Box>
            <br />

            <FormControl>
              <InputLabel
                id="warehouse"
                style={{ fontWeight: 500, fontSize: PP2(16) }}
              >
                Bodega
              </InputLabel>
              <Select
                style={{ width: PP2(300), fontWeight: 500, fontSize: PP2(16) }}
                labelId="select-warehouse"
                name="warehouseId"
                value={formikWarehouseId.values.warehouseId}
                onChange={formikWarehouseId.handleChange}
                label="Bodega"
                required
              >
                <MenuItem
                  style={{ fontWeight: 500, fontSize: PP2(16) }}
                  value={-1}
                  disabled={true}
                >
                  <em>Seleccionar bodega</em>
                </MenuItem>
                {warehouses.map((warehouse, index) => (
                  <MenuItem
                    key={index}
                    value={warehouse.id}
                    style={{ fontSize: PP2(16) }}
                  >
                    {warehouse.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setSelectWarehouseModal(false)}
              color="primary"
              style={{ fontWeight: 500, fontSize: PP2(16) }}
            >
              Cancelar
            </Button>
            <Button
              style={{ fontWeight: 500, fontSize: PP2(16) }}
              type="submit"
              color="primary"
            >
              Filtrar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
