import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { useFormik } from "formik";

import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import CustomButton from "../custom/buttons/default/CustomButton";
import CustomInput from "../custom/forms/default/CustomInput";
import { firstLetterCapitalizeFormat } from "../../utils/formats/firstLetterCapitalizeFormat";
import { setCollectionEditStateModalState } from "../../redux/slices/collections/collectionDetailsInfoModalStatesSlice";
import CustomCollectorPickerInput from "../custom/forms/collections/CustomCollectorPickerInput";
import { fetchUpdateCollectionInfo } from "../../redux/thunks/collections/updateCollectionInfoThunk";
import { fetchCollectionDetails } from "../../redux/thunks/collections/collectionDetailsThunk";
import { useTranslation } from "react-i18next";

export default function CollectionEditAssignmentDetailsForm() {
  const { id } = useParams();
  const { PP } = usePixelPerfect();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { warehouseDetails } = useAppSelector(
    (state) => state.warehouseDetails
  );

  const { selectedCollector } = useAppSelector(
    (state) => state.updateCollectionCollector
  );

  const resetForm = useCallback(async () => {
    dispatch(
      setCollectionEditStateModalState({
        id: "editAssignmentDetail",
        value: false,
      })
    );
  }, [dispatch]);

  const handleClickCloseModal = () => {
    resetForm();
  };

  const initialFormikValues = {
    collectorId: selectedCollector.id,
  };

  const handleSubmit = async () => {
    if (id) {
      dispatch(
        fetchUpdateCollectionInfo({
          data: { collectorId: selectedCollector.id, state: "assigned" },
          collectionId: id,
        })
      );
      await dispatch(fetchCollectionDetails({ id: id }));
      handleClickCloseModal();
    }
  };

  const formikCollectionCollector = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  return (
    <form noValidate onSubmit={formikCollectionCollector.handleSubmit}>
      <CustomInput
        id="warehouse"
        label={t("collectionAssignmentDetail.customEditAssignmentDetailModal.collectionEditAssignmentDetailsForm.Bodega asignada")}
        placeholder="Bodega asignada"
        value={`${firstLetterCapitalizeFormat(warehouseDetails?.name)} `}
        labelStyles={{ fontSize: PP(14), fontWeight: 700 }}
        inputHeight={PP(40)}
        inputBorderRadius={PP(8)}
        backgroundColor="#F6F6F6"
        extraStyle={{ marginTop: PP(24) }}
        disabled
      />

      <CustomCollectorPickerInput
        width={432}
        label={t("collectionAssignmentDetail.customEditAssignmentDetailModal.collectionEditAssignmentDetailsForm.Recolector asignado")}
        required
        id="collectorId"
        error={formikCollectionCollector.errors.collectorId}
      />

      <CustomButton
        width={432}
        height={44}
        noSpacing
        style={{ padding: "0px", marginTop: PP(24) }}
        type="submit"
        label={t("collectionAssignmentDetail.customEditAssignmentDetailModal.collectionEditAssignmentDetailsForm.Guardar cambios")}
        formik={formikCollectionCollector}
        disabled={selectedCollector.id !== -1 ? false : true}
      />
      <CustomButton
        noSpacing
        width={432}
        height={44}
        cancelButton
        onClick={handleClickCloseModal}
        formik={formikCollectionCollector}
      />
    </form>
  );
}
