import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addDays, subDays } from "date-fns";

import {
  destroyRedux,
  getRedux,
  patchRedux,
  postRedux,
} from "../../../utils/old/api";

const initialState = {
  collectionList: [],
  collectors: [],
  collectionState: "",
  totalRows: 0,
  collection: {
    owner: { name: "" },
    city: { name: "" },
    items: [],
    state: "",
    collectorId: 0,
  },
  collectorId: 0,
  collectorsList: [],
  allPrices: [], //Precios generales del país
  warehouses: [],
  allWareHousesNames: [],
  wareHouse: null,
  warehouseIdFilter: -1,
  startDate: `${subDays(new Date(), 0).getFullYear()}-${
    subDays(new Date(), 0).getUTCMonth() + 1
  }-${subDays(new Date(), 0).getDate()}`,
  endDate: `${addDays(new Date(), 0).getFullYear()}-${
    addDays(new Date(), 0).getUTCMonth() + 1
  }-${addDays(new Date(), 0).getDate()}`,
  snackTxt: "",
  open: false,
  failedCollectionSnack: false,
  finishedCollectionSnack: false,
  showLoading: false,
  assignedCollections: 0,
  collectedCollections: 0,
  failedCollections: 0,
};

//Para CollectionList
export const fetchCollectionList = createAsyncThunk(
  //Get
  "collectionList/show",
  ({ cPage, filter }) => {
    return getRedux(
      `/collection?admin=true&$limit=50&$skip=${
        cPage * 50
      }&$sort[date]=-1&${filter}`
    ).then((response) => {
      return response.data;
    });
  }
);

//Para ControlView
export const fetchAssignedCollections = createAsyncThunk(
  //Get
  "collectionAssignedList/show",
  ({ dateFilter, warehouseId }) => {
    let url = `/collection?admin=true&$limit=50&$skip=${
      0 * 50
    }&$sort[date]=-1&state[$in][]=assigned&date[$gte]=${dateFilter}&date[$lte]=${dateFilter}`;

    // Si warehouseId no es igual a -1, lo agrego a la URL
    if (warehouseId !== -1) {
      url += `&warehouseId=${warehouseId}`;
    }

    return getRedux(url).then((response) => {
      return response.data;
    });
  }
);

export const fetchCollectedCollections = createAsyncThunk(
  //Get
  "collectionCollectedList/show",
  ({ dateFilter, warehouseId }) => {
    let url = `/collection?admin=true&$limit=50&$skip=${
      0 * 50
    }&$sort[date]=-1&state[$in][]=collected&date[$gte]=${dateFilter}&date[$lte]=${dateFilter}`;

    // Si warehouseId no es igual a -1, lo agrego a la URL
    if (warehouseId !== -1) {
      url += `&warehouseId=${warehouseId}`;
    }

    return getRedux(url).then((response) => {
      return response.data;
    });
  }
);

export const fetchFailedCollections = createAsyncThunk(
  "collectionFailedList/show",
  ({ dateFilter, warehouseId }) => {
    let url = `/collection?admin=true&$limit=50&$skip=${
      0 * 50
    }&$sort[date]=-1&state[$in][]=failed&date[$gte]=${dateFilter}&date[$lte]=${dateFilter}`;

    // Si warehouseId no es igual a -1, lo agrego a la URL
    if (warehouseId !== -1) {
      url += `&warehouseId=${warehouseId}`;
    }

    return getRedux(url).then((response) => {
      return response.data;
    });
  }
);

//Para CollectionBasicInfo
export const fetchCollection = createAsyncThunk(
  //Get
  "collection/show",
  (id) => {
    return getRedux(`/collection/${id}`).then((response) => {
      return response.data;
    });
  }
);

//Para CollectionStatusCollector
export const fetchCollectors = createAsyncThunk(
  //Get
  "collectors/show",
  () => {
    return getRedux("/collection/collector").then((response) => {
      return response.data;
    });
  }
);

export const fetchUpdateCollection = createAsyncThunk(
  //Patch
  "updateCollection/patch",
  ({ data, id }) => {
    return patchRedux(`/collection/${id}`, data).then((response) => {
      return response.data;
    });
  }
);

//Para CollectionWarehouse
export const fetchWarehouses = createAsyncThunk(
  //Get
  "warehouses/show",
  () => {
    return getRedux(`/warehouse`).then((response) => {
      return response.data;
    });
  }
);

export const fetchWarehouse = createAsyncThunk(
  //Get
  "warehouse/show",
  (id) => {
    return getRedux(`/warehouse/${id}`).then((response) => {
      return response.data;
    });
  }
);

//Para CollectionMaterials
export const fetchPrices = createAsyncThunk(
  //Get
  "customPrices/show",
  (collection) => {
    return getRedux(
      `/material-price?${collection.ownerType}_id=${collection.ownerId}`
    ).then((response) => {
      return response.data;
    });
  }
);

export const fetchAddMaterial = createAsyncThunk(
  //Post
  "addMaterial/post",
  ({ dispatch, data }) => {
    return postRedux("/collection-material", { materials: [data] }).then(
      (response) => {
        return response.data;
      }
    );
  }
);

export const fetchDeleteMaterial = createAsyncThunk(
  //Destroy
  "deleteMaterial/delete",
  (material) => {
    return destroyRedux(`/collection-material/${material.id}`).then(
      (response) => {
        return response.data;
      }
    );
  }
);

//Para CollectionsRoutes
export const fetchCollectorsList = createAsyncThunk(
  //Get
  "collectorsList/show",
  () => {
    return getRedux(`/collection/collector`).then((response) => {
      return response.data;
    });
  }
);

//------------------------------------------------------------------

export const collectionSlice = createSlice({
  name: "collectionReducer",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },

    setSnackTxt: (state, action) => {
      state.snackTxt = action.payload;
    },

    setFailedCollectionSnack: (state, action) => {
      state.failedCollectionSnack = action.payload;
    },

    setFinishedCollectionSnack: (state, action) => {
      state.finishedCollectionSnack = action.payload;
    },

    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },

    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },

    setWarehouseidFilter: (state, action) => {
      state.warehouseIdFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    //1
    builder.addCase(fetchCollectionList.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCollectionList.fulfilled, (state, action) => {
      state.collectionList = action.payload.data;
      state.totalRows = action.payload.total;
      state.showLoading = false;
    });
    //2
    builder.addCase(fetchCollection.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCollection.fulfilled, (state, action) => {
      state.collection = action.payload;
      state.collectionState = action.payload.state;
      state.collectorId = action.payload.collectorId;
      if (state.collectorId === null) state.collectorId = 0;
      state.showLoading = false;
    });
    //3
    builder.addCase(fetchCollectors.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCollectors.fulfilled, (state, action) => {
      state.collectors = action.payload;
      state.showLoading = false;
    });
    //4
    builder.addCase(fetchPrices.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchPrices.fulfilled, (state, action) => {
      state.allPrices = action.payload;
      state.showLoading = false;
    });
    builder.addCase(fetchPrices.rejected, (state, action) => {
      state.allPrices = action.payload;
      state.showLoading = false;
    });
    // 6-fetchUpdateCollection
    builder.addCase(fetchUpdateCollection.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchUpdateCollection.fulfilled, (state) => {
      state.showLoading = false;
      state.snackTxt = "Recolección actualizada";
      state.open = true;
      fetchCollection();
    });
    builder.addCase(fetchUpdateCollection.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error actualizando recolección");
    });
    // 7-fetchAddMaterial
    builder.addCase(fetchAddMaterial.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchAddMaterial.fulfilled, (state) => {
      state.showLoading = false;
      state.open = true;
    });
    builder.addCase(fetchAddMaterial.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error agregando material");
    });
    //8-fetchDeleteMaterial
    builder.addCase(fetchDeleteMaterial.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchDeleteMaterial.fulfilled, (state) => {
      state.showLoading = false;
      state.open = true;
    });
    builder.addCase(fetchDeleteMaterial.rejected, (state, action) => {
      state.showLoading = false;
      alert("Error eliminando material");
    });
    //8-fetchCollectorsList
    builder.addCase(fetchCollectorsList.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCollectorsList.fulfilled, (state, action) => {
      state.collectorsList = action.payload;
      state.showLoading = false;
    });
    builder.addCase(fetchCollectorsList.rejected, (state) => {
      state.showLoading = false;
      alert("Error obteniendo lista de recolectores");
    });
    //9-fetchWarehouses
    builder.addCase(fetchWarehouses.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchWarehouses.fulfilled, (state, action) => {
      state.allWareHousesNames = action.payload.data;
      state.warehouses = action.payload.data;
      state.showLoading = false;
    });
    builder.addCase(fetchWarehouses.rejected, (state) => {
      state.showLoading = false;
      alert("Error obteniendo datos de Bodegas");
    });
    //10-fetchWarehouse
    builder.addCase(fetchWarehouse.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchWarehouse.fulfilled, (state, action) => {
      state.wareHouse = action.payload;
      state.showLoading = false;
    });
    builder.addCase(fetchWarehouse.rejected, (state) => {
      state.showLoading = false;
      alert("Error obteniendo datos de Bodega");
    });
    //11-FetchAssignedCollections
    builder.addCase(fetchAssignedCollections.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchAssignedCollections.fulfilled, (state, action) => {
      state.assignedCollections = action.payload.total;
      state.showLoading = false;
    });
    //12-fetchCollectedCollections
    builder.addCase(fetchCollectedCollections.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchCollectedCollections.fulfilled, (state, action) => {
      state.collectedCollections = action.payload.total;
      state.showLoading = false;
    });
    //13-fetchFailedCollections
    builder.addCase(fetchFailedCollections.pending, (state) => {
      state.showLoading = true;
    });
    builder.addCase(fetchFailedCollections.fulfilled, (state, action) => {
      state.failedCollections = action.payload.total;
      state.showLoading = false;
    });
  },
});

export const {
  setOpen,
  setSnackTxt,
  setFailedCollectionSnack,
  setFinishedCollectionSnack,
  setStartDate,
  setEndDate,
  setWarehouseidFilter,
} = collectionSlice.actions;
export default collectionSlice.reducer;
