import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "@emotion/styled";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as MuiTextField,
  Select,
  Snackbar,
  MenuItem,
  Alert,
} from "@mui/material";
import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import {
  documentTypeOptions,
  entityBusinessTypes,
  regimenBusinessOptions,
} from "../../../../../utils/old/constants";
import PageHeader from "../../../../../components/old/generalOld/PageHeader";
import UsersTable from "../../../../../components/old/users/old/business/UsersTable";
import {
  fetchBusinessList,
  fetchCreateBusiness,
  setShowSnack,
} from "../../../../../redux/old/slices/businessSlicer";
import useAuth from "../../../../../hooks/old/useAuth";

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const headCells = [
  { id: "name", alignment: "left", label: "Nombre" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "Nit", alignment: "left", label: "NIT" },
  { id: "actions", alignment: "right", label: "Acciones" },
];

export default function BusinessList() {
  const [createModal, setCreateModal] = useState(false);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const { user } = useAuth();

  //ReduxToolkit
  const loading = useSelector((state) => state.business.showLoading);
  const snackTxt = useSelector((state) => state.business.snackTxt);
  const showSnack = useSelector((state) => state.business.showSnack);

  const dispatch = useDispatch();

  const getBusinesses = async (cPage) => {
    dispatch(fetchBusinessList(cPage)); //ReduxTookit Get
    setPage(cPage);
  };

  const initialValues = {
    email: "",
    name: "",
    last_name: "",
    document_type: t("createNewBusinessModal.SELECCIONA EL TIPO DE DOCUMENTO"),
    legal_name: "",
    document_number: "",
    phone: "",
    countryId: 1,
    regime: t("createNewBusinessModal.SELECCIONA EL RÉGIMEN TRIBUTARIO"),
    segment: t("createNewBusinessModal.SELECCIONA EL TIPO DE NEGOCIO"),
    payment_method: t("createNewBusinessModal.SELECCIONA MÉTODO DE PAGO"),
    operator: t("createNewBusinessModal.SELECCIONA EL TIPO DE OPERADOR"),
    admin: true,
    internal_code: "",
  };

  function validationSchema() {
    return {
      email: Yup.string()
        .email(t("createNewBusinessModal.validations.Correo no válido"))
        .required(t("createNewBusinessModal.validations.Correo requerido"))
        .max(255, t("createNewBusinessModal.validations.máx 255 caracteres")),
      name: Yup.string()
        .min(3, t("createNewBusinessModal.validations.Mín 3 caracteres"))
        .required(t("createNewBusinessModal.validations.Nombre requerido")),
      legal_name: Yup.string()
        .min(3, t("createNewBusinessModal.validations.Mín 3 caracteres"))
        .required(
          t("createNewBusinessModal.validations.Nombre legal requerido")
        ),
      phone: Yup.number().required(
        t("createNewBusinessModal.validations.Teléfono requerido")
      ),
      regime: Yup.string()
        .required(t("createNewBusinessModal.validations.Régimen requerido"))
        .oneOf(["simplified", "comun", "special"]),
      segment: Yup.string()
        .required(
          t("createNewBusinessModal.validations.Tipo de negocio requerido")
        )
        .oneOf([
          "restaurant",
          "retail",
          "industry",
          "educational",
          "building",
          "hotel",
          "food",
          "entertainment",
          "health",
          "construction",
          "government",
          "workshop",
          "wellness",
          "enviromental",
          "logistic",
          "compensation",
          "factory",
          "mall",
          "law",
          "funeral",
          "technology",
          "special",
          "office",
          "other",
        ]),
      payment_method: Yup.string()
        .required(
          t("createNewBusinessModal.validations.Método de pago requerido")
        )
        .oneOf(["bank_transfer", "cash"]),
      operator: Yup.string()
        .required(t("createNewBusinessModal.validations.Operador requerido"))
        .oneOf(["tf", "external", "muta"]),
      document_type: Yup.string()
        .required(
          t("createNewBusinessModal.validations.Tipo de documento requerido")
        )
        .oneOf(["cc", "nit"]),
      document_number: Yup.string()
        .required(
          t("createNewBusinessModal.validations.Número de documento requerido")
        )
        .matches(
          /^[0-9]+$/,
          t(
            "createNewBusinessModal.validations.El número de documento debe contener justamente sólo números, sin espacios ni guion"
          )
        )
        .matches(
          /^[^\s-]+$/,
          t(
            "createNewBusinessModal.validations.El número de documento no puede contener espacios ni guiones"
          )
        ),
      internal_code: Yup.string().matches(
        /^[a-zA-Z0-9]+$/,
        t(
          "createNewBusinessModal.validations.Ingresa una combinación válida de números y letras"
        )
      ),
    };
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) => {
      createBusiness(data);
    },
  });

  const createBusiness = async (data) => {
    //ReduxTookit Post
    let r = (Math.random() + 1).toString(36).substring(2);
    const params = {
      admin: true,
      email: data.email,
      password: r,
      name: data.name,
      last_name: data.legal_name,
      document_type: "person_id",
      document_number: data.document_number,
      phone: data.phone,
      business: {
        commercial_name: data.name,
        name: data.name,
        legal_name: data.legal_name,
        document_type: data.document_type,
        document_number: data.document_number,
        email: data.email,
        phone: data.phone,
        countryId: 1,
        payment_method: data.payment_method,
        segment: data.segment,
        regime: data.regime,
        operator: data.operator,
        internal_code: data.internal_code,
      },
      licenseId: user.licenseId,
    };
    dispatch(fetchCreateBusiness(params));
    setCreateModal(false);
    getBusinesses(0);
  };

  return (
    <React.Fragment>
      <PageHeader title={t("Negocios")}>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => setCreateModal(true)}
            >
              <AddIcon />
              {t("createNewBusinessModal.Nuevo")}
            </Button>
          </div>
        </Grid>
      </PageHeader>

      <Divider my={6} />

      <UsersTable headCells={headCells} />

      {/* -------------------------------------------Modal y SnackBar----------------------------------------------------- */}

      <Dialog
        open={createModal}
        onClose={() => setCreateModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("createNewBusinessModal.Crear un negocio")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                "createNewBusinessModal.Cada negocio creado tendrá asignado una cuenta con la que podrá ingresar y cambiar su contraseña"
              )}
            </DialogContentText>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  name="name"
                  label={t("createNewBusinessModal.Nombre comercial")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.name && (
                  <Alert mt={2} mb={3} severity="error">
                    {formik.errors.name}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="legal_name"
                  label={t("createNewBusinessModal.Nombre legal")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.legal_name}
                  onChange={formik.handleChange}
                />
                {formik.errors.legal_name && (
                  <Alert mt={2} mb={3} severity="error">
                    {formik.errors.legal_name}
                  </Alert>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  name="email"
                  label={t("createNewBusinessModal.Correo")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email && (
                  <Alert mt={2} mb={3} severity="error">
                    {formik.errors.email}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="phone"
                  label={t("createNewBusinessModal.Teléfono")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
                {formik.errors.phone && (
                  <Alert mt={2} mb={3} severity="error">
                    {formik.errors.phone}
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6} my={2}>
                <Select
                  name="document_type"
                  label={t("createNewBusinessModal.Tipo de documento")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.document_type}
                  onChange={formik.handleChange}
                >
                  <MenuItem
                    key={"label"}
                    value={t(
                      "createNewBusinessModal.SELECCIONA EL TIPO DE DOCUMENTO"
                    )}
                    disabled={true}
                  >
                    {t(
                      "createNewBusinessModal.SELECCIONA EL TIPO DE DOCUMENTO"
                    )}
                  </MenuItem>

                  {documentTypeOptions.map((documentType, index) => (
                    <MenuItem key={index} value={documentType.value}>
                      {t(
                        `createNewBusinessModal.documentTypeOptions.${documentType.label}`
                      )}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.document_type && (
                  <Alert mt={2} mb={3} severity="error">
                    {t(
                      "createNewBusinessModal.validations.Documento requerido"
                    )}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="document_number"
                  label={
                    formik.values.document_type !==
                    "SELECCIONA EL TIPO DE DOCUMENTO"
                      ? formik.values.document_type === "nit"
                        ? t("createNewBusinessModal.Número del Nit")
                        : t("createNewBusinessModal.Número de cédula")
                      : t("createNewBusinessModal.Número de documento")
                  }
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.document_number}
                  onChange={formik.handleChange}
                />
                {formik.errors.document_number && (
                  <Alert mt={2} mb={3} severity="error">
                    {formik.errors.document_number}
                  </Alert>
                )}
                {formik.values.document_type === "nit" && (
                  <Alert mt={2} mb={3} severity="warning">
                    {t(
                      "createNewBusinessModal.Mutante, recuerda ingresar el Nit SIN código de verificación"
                    )}
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6} my={2}>
                <Select
                  name="regime"
                  label={t("createNewBusinessModal.Regimen")}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.regime}
                  onChange={formik.handleChange}
                >
                  <MenuItem
                    key={"label"}
                    value={t(
                      "createNewBusinessModal.SELECCIONA EL RÉGIMEN TRIBUTARIO"
                    )}
                    disabled={true}
                  >
                    {t(
                      "createNewBusinessModal.SELECCIONA EL RÉGIMEN TRIBUTARIO"
                    )}
                  </MenuItem>

                  {regimenBusinessOptions.map((regimen, index) => (
                    <MenuItem key={index} value={regimen.value}>
                      {t(
                        `createNewBusinessModal.regimenBusinessOptions.${regimen.label}`
                      )}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.regime && (
                  <Alert mt={2} mb={3} severity="error">
                    {t(`createNewBusinessModal.validations.Régimen requerido`)}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6} my={2}>
                <Select
                  name="segment"
                  label={t(`createNewBusinessModal.Segmento`)}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.segment}
                  onChange={formik.handleChange}
                >
                  {entityBusinessTypes.map((type, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={type.value}
                        disabled={index === 0 ? true : false}
                      >
                        {index === 0
                          ? t(`createNewBusinessModal.${type.label}`)
                          : t(
                              `createNewBusinessModal.entityBusinessTypes.${type.label}`
                            )}
                      </MenuItem>
                    );
                  })}
                </Select>
                {formik.errors.segment && (
                  <Alert mt={2} mb={3} severity="error">
                    {t(
                      `createNewBusinessModal.validations.Tipo de negocio requerido`
                    )}
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6} my={2}>
                <Select
                  name="operator"
                  label={t(`createNewBusinessModal.validations.Operador`)}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.operator}
                  onChange={formik.handleChange}
                >
                  <MenuItem
                    key={"label"}
                    value={t(
                      `createNewBusinessModal.SELECCIONA EL TIPO DE OPERADOR`
                    )}
                    disabled={true}
                  >
                    {t(`createNewBusinessModal.SELECCIONA EL TIPO DE OPERADOR`)}
                  </MenuItem>
                  <MenuItem key={"team foods"} value={"tf"}>
                    Team Foods, " tf "
                  </MenuItem>
                  <MenuItem key={"external"} value={"external"}>
                    External
                  </MenuItem>
                  <MenuItem key={"muta"} value={"muta"}>
                    Muta
                  </MenuItem>
                </Select>
                {formik.errors.operator && (
                  <Alert mt={2} mb={3} severity="error">
                    {t(`createNewBusinessModal.validations.Operador requerido`)}
                  </Alert>
                )}
              </Grid>
              <Grid item md={6} my={2}>
                <Select
                  name="payment_method"
                  label={t(`createNewBusinessModal.Medio de pago`)}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.payment_method}
                  onChange={formik.handleChange}
                >
                  <MenuItem
                    key={"label"}
                    value={t(
                      `createNewBusinessModal.SELECCIONA MÉTODO DE PAGO`
                    )}
                    disabled={true}
                  >
                    {t(`createNewBusinessModal.SELECCIONA MÉTODO DE PAGO`)}
                  </MenuItem>
                  <MenuItem key={"cash"} value={"cash"}>
                    {t(`createNewBusinessModal.Efectivo`)}
                  </MenuItem>
                  <MenuItem key={"bank_transfer"} value={"bank_transfer"}>
                    {t(`createNewBusinessModal.Transferencia`)}
                  </MenuItem>
                </Select>
                {formik.errors.payment_method && (
                  <Alert mt={2} mb={3} severity="error">
                    {t(
                      `createNewBusinessModal.validations.Método de pago requerido`
                    )}
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  name="internal_code"
                  label={t(`createNewBusinessModal.Código interno`)}
                  fullWidth
                  variant="outlined"
                  my={2}
                  value={formik.values.internal_code}
                  onChange={formik.handleChange}
                />
                {formik.errors.internal_code && (
                  <Alert mt={2} mb={3} severity="error">
                    {formik.errors.internal_code}
                  </Alert>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCreateModal(false)} color="primary">
              {t("createNewBusinessModal.Cancelar")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("createNewBusinessModal.Crear")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(setShowSnack(false));
        }}
        message={t(snackTxt)}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                dispatch(setShowSnack(false));
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
