import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateArrayLocationMessage } from "../../../../../redux/old/slices/googleMapsSlicer";

const feathers = require("@feathersjs/feathers");
const socketio = require("@feathersjs/socketio-client");
const io = require("socket.io-client");

export default function CurrentLocation() {
  const { selectedCollectorsId } = useSelector((state) => state.googleMaps);
  let connected = false;

  const dispatch = useDispatch();

  useEffect(() => {
    configSocket();
  }, [selectedCollectorsId]);

  const configSocket = async () => {
    if (!connected) {
      const socket =
        io(
          process.env.REACT_APP_WEBSOCKET_URL || "https://websocket.mutaworld.com"
        );
      const app = feathers();
      app.configure(
        socketio(socket, {
          timeout: 2000,
        })
      );
      socket.on("connect", () => {
        socket.emit(
          "create",
          "authentication",
          {
            strategy: "jwt",
            accessToken: window.localStorage.getItem("accessToken"),
          },
          function (error, newAuthResult) {
            connected = true;
          }
        );
      });

      socket.on("disconnect", () => {
        setTimeout(() => {
          configSocket();
        }, 5000);
      });

      socket.on("location patched", function (message) {
        dispatch(updateArrayLocationMessage(message));
      });
    }
  };

  return <></>;
}
