import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Divider, Modal, Toolbar } from "@mui/material";

import CustomCompleteTable from "../../components/custom/tables/default/CustomCompleteTable";
import CustomHeaderTitle from "../../components/custom/headers/default/CustomHeaderTitle";
import { allCollectionsTableHeadCells } from "../../utils/constants/collections/allCollectionsTableHeadCells";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchCollectionsList } from "../../redux/thunks/collections/collectionListThunk";
import CustomSearchFilters from "../../components/custom/headers/default/CustomSearchFilters";
import { fetchSearchAllCollections } from "../../redux/thunks/collections/collectionListSearchThunk";
import CustomCollectionsTableRow from "../../components/custom/tables/collections/CustomCollectionsTableRow";
import usePixelPerfect from "../../hooks/old/usePixelPerfect";
import CollectionWarehouseFilterModal from "../../components/custom/modals/collections/CollectionWarehouseFilterModal";
import SelectCityWarehouseFilterModal from "../../components/custom/modals/collections/SelectCityWarehouseFilterModal";
import SelectWarehouseOfListModal from "../../components/custom/modals/collections/SelectWarehouseOfListModal";
import CollectionsFilterByModal from "../../components/custom/modals/collections/CollectionsFilterByModal";
import SelectCollectorOfListModal from "../../components/custom/modals/collections/SelectCollectorOfListModal";
import FilterControl from "../../components/collections/FilterControl";
import { setCollectionsFilter } from "../../redux/slices/collections/collectionListSlice";
import { setSubmit } from "../../redux/slices/collections/collectionsListOtherFiltersBySlice";

export default function AllCollections() {
  const { PP } = usePixelPerfect();
  const ref = useRef();
  const { t } = useTranslation();

  const { openWarehouseFilterModal, selectCity, selectWarehouse } =
    useAppSelector((state) => state.collectionsWarehouseFilterModalStates);

  const { openFilterByModal, selectCollectorFilterModal } = useAppSelector(
    (state) => state.collectionsListFilterByModalStates
  );

  const { filter } = useAppSelector((state) => state.collectionList);

  const [page, setPage] = useState<number>(0);
  const [currentDelay, setCurrentDelay] = useState<NodeJS.Timeout | undefined>(
    undefined
  );
  const [allCollectionsSearch, setAllCollectionsSearch] =
    useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [collectionsFiltersQuery, setCollectionsFiltersQuery] =
    useState<string>(filter);
  const { status, collectionList, totalRows } = useAppSelector(
    (state) => state.collectionList
  );
  const dispatch = useAppDispatch();

  const { warehouseFilter } = useAppSelector(
    (state) => state.collectionsListWarehouseFilter
  );

  const {
    statesCollectionsListFilterQuery,
    rangeDateCollectionsListFilterQuery,
    cityCollectionsListFilterQuery,
    collectorCollectionsListFilterQuery,
    typeOfMaterialCollectionsListFilterQuery,
    paymentMethodCollectionsListFilterQuery,
    submit,
  } = useAppSelector((state) => state.collectionsListOtherFilters);

  useEffect(() => {
    if (submit) {
      const updatedCollectionsFiltersQuery =
        cityCollectionsListFilterQuery +
        warehouseFilter +
        statesCollectionsListFilterQuery +
        rangeDateCollectionsListFilterQuery +
        collectorCollectionsListFilterQuery +
        typeOfMaterialCollectionsListFilterQuery +
        paymentMethodCollectionsListFilterQuery;

      setCollectionsFiltersQuery(updatedCollectionsFiltersQuery);
      dispatch(setCollectionsFilter(updatedCollectionsFiltersQuery));
      dispatch(setSubmit(false));
    }
  }, [
    dispatch,
    submit,
    cityCollectionsListFilterQuery,
    warehouseFilter,
    statesCollectionsListFilterQuery,
    rangeDateCollectionsListFilterQuery,
    collectorCollectionsListFilterQuery,
    typeOfMaterialCollectionsListFilterQuery,
    paymentMethodCollectionsListFilterQuery,
  ]);

  useEffect(() => {
    dispatch(fetchCollectionsList({ page: page, filter: filter }));
  }, [filter, page, dispatch]);

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (event !== null) {
      setPage(newPage);
    }
  };

  //Lista por búsqueda de negocios
  const getAllCollectionsSearch = async (text: string) => {
    setText(text);
    await dispatch(
      fetchSearchAllCollections({
        query: text !== text.toLowerCase() ? text.toLowerCase() : text,
      })
    );
  };

  const searchAllCollections = async (text: string) => {
    clearTimeout(currentDelay);
    setCurrentDelay(
      setTimeout(async () => {
        if (text.length <= 2) {
          dispatch(fetchCollectionsList({ page: 0 }));
          setAllCollectionsSearch(false);
        } else {
          getAllCollectionsSearch(text);
          setAllCollectionsSearch(true);
        }
      }, 1000)
    );
  };

  return (
    <>
      <Toolbar
        style={{
          padding: "0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CustomHeaderTitle
          title={t("customHeaderTitle.Recolecciones")}
          style={{ fontSize: PP(36), fontWeight: 500 }}
        />
        <CustomSearchFilters
          placeholder={"Buscar"}
          onChange={(e) => searchAllCollections(e.target.value)}
          aboutCustomFilters="allCollections"
          width={210}
          height={40}
          widthsearchicon={20}
          heightsearchicon={20}
        />
      </Toolbar>
      <Divider />

      <FilterControl />

      <CustomCompleteTable
        status={status}
        tableHeadCells={allCollectionsTableHeadCells} //Encabezados de la tabla
        totalRows={totalRows} // total de filas de la tabla = total de datos respuesta backend
        rowsPerPage={50} //Filas por pagina de tabla
        page={page} //página actual
        handleChangePage={handleChangePage} // Manejando cambio de página para consumo Api
        setPage={setPage} //Cambio de página actual
        children={collectionList?.map((element, key) => {
          return (
            <CustomCollectionsTableRow
              element={element}
              key={key}
              myKey={key}
            />
          );
        })}
      />
      {/* Modales */}
      {/* Flujo modal filtro por ciudad y bodega */}
      <Modal
        open={openWarehouseFilterModal}
        children={<CollectionWarehouseFilterModal ref={ref} />}
      />
      <Modal
        open={selectCity}
        children={<SelectCityWarehouseFilterModal ref={ref} />}
      />
      <Modal
        open={selectWarehouse}
        children={<SelectWarehouseOfListModal ref={ref} />}
      />
      {/* Flujo modal con todos los demás filtros */}
      <Modal
        open={openFilterByModal}
        children={<CollectionsFilterByModal ref={ref} />}
      />

      <Modal
        open={selectCollectorFilterModal}
        children={<SelectCollectorOfListModal ref={ref} />}
      />
    </>
  );
}
