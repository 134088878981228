import { createSlice } from "@reduxjs/toolkit";

import { WarehouseDetailsSliceType } from "../../../interfaces/redux/warehouseDetailsSliceInterface";
import { fetchWarehouseDetails } from "../../thunks/warehouse/warehouseDetailsThunk";

const initialState: WarehouseDetailsSliceType = {
  status: "idle",
  warehouseDetails: {
    name: "",
  },
};

export const warehouseDetailsSlice = createSlice({
  name: "warehouseDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get detalles de una bodega
    builder.addCase(fetchWarehouseDetails.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchWarehouseDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.warehouseDetails = action.payload;
    });
    builder.addCase(fetchWarehouseDetails.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const {} = warehouseDetailsSlice.actions;

export default warehouseDetailsSlice.reducer;
