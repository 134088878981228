import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";

import doneIcon from "../../../../vendor/old/done.png";
import boxIcon from "../../../../vendor/old/boxIcon.png";
import recyclingTruckIcon from "../../../../vendor/old/recyclingTruckIcon.png";
import { decodePolyline } from "../../../../utils/old/decodePolyline";
import { mapStyles } from "../../../../utils/old/constants";

function CollectionsRoutesMap() {
  const {
    arrayDonePoints,
    arrayToDoPoints,
    arrayPolylinesToDecode,
    selectedCollectorsId,
    arrayLocationMessage,
  } = useSelector((state) => state.googleMaps);
  const { collectorsList } = useSelector((state) => state.collection);
  const [selectedTruck, setSelectedTruck] = useState(null);
  const [collectorNameMarker, setCollectorNameMarker] = useState(null);
  const [collectorPhoneMarker, setCollectorPhoneMarker] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);

  const mapContainerStyle = {
    height: "100%",
    width: "100%",
  };

  let paths = [];

  arrayPolylinesToDecode !== [] &&
    arrayPolylinesToDecode.map((polylineToDecode) => {
      let patch = null;
      patch = decodePolyline(polylineToDecode); //Cada polilínea decodificada Es un array
      paths.push(patch);
    });

  // const center = {
  //   lat: 4.60971, //10.96854,
  //   lng: -74.08175, //-74.78132,
  // };

  const mapRef = useRef(null);
  const [mapBounds, setMapBounds] = useState(null);

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    paths: paths,
    zIndex: 1,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAGrJxvfXC9U9b2LL7pFSzQ5kRVy6vtZoM",
  });

  const onLoad = (marker) => {
    // console.log("marker: ", marker);
  };
  const collectorName = (locationMessage) => {
    let collectorMarkerInfo = collectorsList.filter(
      (collector) => locationMessage.userId === collector.id
    );
    setCollectorNameMarker(
      `${collectorMarkerInfo[0].name} ${collectorMarkerInfo[0].last_name}`
    );
    setCollectorPhoneMarker(` ${collectorMarkerInfo[0].phone}`);
  };

  useEffect(() => {
    // console.log(arrayLocationMessage);
  }, [arrayLocationMessage]);

  useEffect(() => {
    const updateBounds = () => {
      if (arrayLocationMessage.length > 0 && mapRef.current) {
        const bounds = new window.google.maps.LatLngBounds();

        arrayLocationMessage.forEach((locationMessage) => {
          bounds.extend(
            new window.google.maps.LatLng(
              locationMessage.lat,
              locationMessage.lng
            )
          );
        });

        setMapBounds(bounds);
      }
    };

    // Actualizar los límites inicialmente
    updateBounds();

    // Establecer la actualización periódica cada 10 minutos
    const interval = setInterval(updateBounds, 10 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleMapLoad = (map) => {
    mapRef.current = map;
  };

  return isLoaded ? (
    <GoogleMap
      id="mapa"
      mapContainerStyle={mapContainerStyle}
      zoom={12}
      center={mapBounds ? undefined : { lat: 10.96854, lng: -74.78132 }}
      bounds={mapBounds}
      options={{
        styles: mapStyles,
        maxZoom: 15, // Establece el nivel máximo de zoom permitido
      }}
      onLoad={handleMapLoad}
    >
      {paths !== [] &&
        paths.map((path, index) => {
          return (
            <Polyline
              key={index}
              onLoad={onLoad}
              path={path}
              options={options}
            />
          );
        })}
      {arrayDonePoints.flat().map((point, index) => {
        //Puntos "Done"
        return (
          <Marker
            key={index}
            onLoad={onLoad}
            position={{ lat: point.lat, lng: point.lng }}
            icon={{ url: doneIcon }}
            onClick={() => {
              setSelectedPoint(point);
            }}
          />
        );
      })}

      {arrayToDoPoints.flat().map((point, index) => {
        //Puntos "To Do"
        return (
          <Marker
            key={index}
            onLoad={onLoad}
            position={{ lat: point.lat, lng: point.lng }}
            icon={{ url: boxIcon }}
            onClick={() => {
              setSelectedPoint(point);
            }}
          />
        );
      })}

      {selectedPoint && (
        <InfoWindow
          onCloseClick={() => {
            setSelectedPoint(null);
          }}
          position={{
            lat: selectedPoint.lat,
            lng: selectedPoint.lng,
          }}
        >
          <div style={{ color: "#23303f", fontWeight: "bolder" }}>
            <p>Cliente: {selectedPoint.ownerName}</p>
            <p>Dirección: {selectedPoint.addressDescription}</p>
            <p>Teléfono: {selectedPoint.ownerPhone}</p>
          </div>
        </InfoWindow>
      )}

      {arrayLocationMessage !== undefined &&
        arrayLocationMessage.map((locationMessage, index) => {
          if (selectedCollectorsId.includes(locationMessage.userId)) {
            const collectorNameLabel = collectorsList.filter(
              (collector) => locationMessage.userId === collector.id
            )[0].name;
            return (
              <Marker
                key={index}
                onLoad={onLoad}
                position={{
                  lat: locationMessage.lat,
                  lng: locationMessage.lng,
                }}
                icon={{
                  url: recyclingTruckIcon,
                  labelOrigin: new window.google.maps.Point(20, -3),
                }}
                onClick={() => {
                  setSelectedTruck(locationMessage);
                  collectorName(locationMessage);
                }}
                label={{
                  text: collectorNameLabel,
                  color: "#ffffff",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              />
            );
          }
        })}

      {selectedTruck && (
        <InfoWindow
          onCloseClick={() => {
            setSelectedTruck(null);
          }}
          position={{
            lat: selectedTruck.lat + 0.01,
            lng: selectedTruck.lng + 0.01,
          }}
          boxStyle={{
            borderRadius: "4px",
            fontSize: "14px",
            lineHeight: "1.4",
          }}
        >
          <div style={{ color: "#23303f", fontWeight: "bolder" }}>
            <p>Recolector: {collectorNameMarker && collectorNameMarker}</p>
            <p>Cel: {collectorPhoneMarker && collectorPhoneMarker}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(CollectionsRoutesMap);
